import { useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';

interface GitInfo {
  git: {
    commit: {
      id: { abbrev: string };
    };
    tags: string;
  };
}

const backendVersionQueryFunction = async () => (await axios.get<GitInfo>('/api/actuator/info')).data;

export const useBackendVersionQuery = () => {
  const { data } = useSuspenseQuery<GitInfo>({
    queryKey: ['backendVersion'],
    queryFn: backendVersionQueryFunction,
  });
  return data;
};
