import { Link, Sheet, Typography, styled } from '@healthinal/ui';
import { useBackendVersionQuery } from '../state/api/version';
import { theme } from '../theme';

export default function Footer() {
  const version = useBackendVersionQuery();
  const versionDisplay = version.git.tags !== '' ? version.git.tags : version.git.commit.id.abbrev;
  return (
    <footer style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}>
      <Root>
        <div>
          <ContactInfo>
            <FooterText>Bei Fragen stehen wir gerne zur Verfügung: </FooterText>
            <FooterText>
              <ContactLink href={`mailto:support@healthinal.com?subject=SMC-Version:${versionDisplay}`}>
                support@healthinal.com
              </ContactLink>
            </FooterText>
            <FooterText>/</FooterText>
            <FooterText>
              <ContactLink href={'tel:+41-55-511-04-64'}>+41 55 511 04 64</ContactLink>
            </FooterText>
          </ContactInfo>
          <FooterText>Version: {versionDisplay}</FooterText>
        </div>
      </Root>
    </footer>
  );
}

const Root = styled(Sheet)`
  padding: 8px 16px;
  background-color: ${theme.vars.palette.neutral[200]};
  > div {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterText = styled(Typography)`
  font-size: 14px;
`;

const ContactInfo = styled('div')`
  display: flex;
  gap: 8px;
`;

const ContactLink = styled(Link)`
  color: ${theme.vars.palette.primary[600]};
`;
