import { Button, styled, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { ApiError } from '../../state/api/generated';
import { theme } from '../../theme';
import ContentSection from '../ContentSection';
import { ErrorHeader } from '../Header';

export function GlobalErrorScreen(props: FallbackProps) {
  return (
    <>
      <ErrorHeader />
      <ContentSection>
        <Wrapper>
          <ErrorInformation {...props} />
          <Button onClick={() => window.location.reload()}>Seite neu laden</Button>
        </Wrapper>
      </ContentSection>
    </>
  );
}

interface ComponentErrorProps extends FallbackProps {
  additionalMessage?: string;
  children?: ReactNode;
}
export function InlineError({ additionalMessage, children, ...props }: ComponentErrorProps) {
  return (
    <ContentSection>
      <Wrapper>
        <ErrorInformation {...props} />
        <Typography>{additionalMessage}</Typography>
        {children}
      </Wrapper>
    </ContentSection>
  );
}

function ErrorInformation({ error }: FallbackProps) {
  return (
    <>
      {error instanceof ApiError ? (
        <>
          <Title level={'h2'}>{error.status}</Title>
          <Typography>{getErrorDescription(error)}</Typography>
        </>
      ) : (
        <>
          <Title level={'h2'}>Fehler</Title>
          <Typography>Ein unerwarteter Fehler ist aufgetreten</Typography>
        </>
      )}
    </>
  );
}

function getErrorDescription(error: ApiError) {
  switch (error.status) {
    case 500: {
      return 'Unbekannter Fehler';
    }
    case 404: {
      return 'Nicht gefunden';
    }
    case 403: {
      return 'Kein Zugriff';
    }
    default: {
      return error.message;
    }
  }
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  gap: 24px;
`;

const Title = styled(Typography)`
  color: ${theme.vars.palette.primary[800]};
`;
