import { Divider, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, styled, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';
import blockImage from '../assets/help/block.png';
import connectImage from '../assets/help/connect.png';
import grantAccessNow from '../assets/help/grant_access_now.png';
import patientNotFoundImage from '../assets/help/patient_not_found.png';
import { theme } from '../theme';

interface Props {
  open: boolean;
  close: () => void;
}

export function Help({ open, close }: Props) {
  return (
    <Modal open={open} onClose={() => close()}>
      <ModalOverflow>
        <ModalDialog sx={{ maxWidth: 880, '--Card-padding': '48px' }}>
          <ModalClose />
          <Typography level="h4" gutterBottom>
            Willkommen bei Smart Managed Care
          </Typography>
          Mit Smart Managed Care (SMC) erhält das telemedizinische Zentrum bei einem Anruf von Patient:innen Zugriff auf
          Inhalte der Krankengeschichte in der Hausarztpraxis.
          <Divider inset="none" sx={{ my: 4 }} />
          <Stack gap={3}>
            <Step step={1} title="Initiale Verbindung mit Ihrem Praxisinformationssystem (PIS)">
              Falls notwendig, verbinden Sie SMC mit Heureka Health und erlauben Sie den Zugriff auf Ihr PIS, damit die
              Daten der Managed-Care Versicherten für die Telemedizin freigegeben werden können.
              <Image src={connectImage} width={320} alt="" />
            </Step>
            <Step step={2} title="Automatische Freigabe für Telemedizin">
              Die Patientenstammdaten aus Ihrem PIS werden automatisch mit den Managed-Care Daten verknüpft und Inhalte
              der Krankengeschichte (KG) der Telemedizin nach 14 Tagen automatisch zur Einsicht zur Verfügung gestellt.
              Über die Aktion «Jetzt freigeben» besteht die Möglichkeit, die Inhalte der KG der Telemedizin ohne
              Verzögerung freizugeben.
              <Image src={grantAccessNow} width={380} alt="" />
            </Step>
            <Step step={3} title="Nicht gefundene Patientendaten manuell verknüpfen">
              Konnten die Patientenstammdaten nicht verknüpft werden, weil beispielsweise unterschiedliche Schreibweisen
              des Namens vorliegen, muss die Verknüpfung über «Bereinigen» manuell durchgeführt werden. Tragen Sie die
              Patienten-ID aus Ihrem PIS im Suchfeld ein und verknüpfen Sie den richtigen Eintrag.
              <Image src={patientNotFoundImage} width={360} alt="" />
            </Step>
            <Step step={4} title="Unbekannte Patient:innen">
              Falls ein:e Patient:in noch nie in Ihrer Praxis war, können Sie dies dem System über «Bereinigen» und
              anschliessend über «Als &quot;war nie in Praxis&quot; markieren» mitteilen und der Eintrag wird aus der
              Liste «Nicht gefunden» entfernt und befindet sich im Filter «War noch nie in Praxis». Falls der/die
              Patient:in zu einem späteren Zeitpunkt in die Praxis kommt, kann die Verknüpfung manuell erstellt werden.
            </Step>
            <Step step={5} title="Keine Freigabe für Telemedizin ">
              Falls spezifische KGs der Telemedizin nicht freigegeben werden sollen, wählen Sie «Sperren» an.
              <Image src={blockImage} width={160} alt="" />
            </Step>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}

interface StepProps {
  step: number;
  title: string;
  children: ReactNode;
}

function Step({ step, title, children }: StepProps) {
  return (
    <Stack direction="row" gap={1.5}>
      <StepNumber>{step}</StepNumber>
      <Stack>
        <Typography level="title-lg" gutterBottom>
          {title}
        </Typography>
        {children}
      </Stack>
    </Stack>
  );
}

const StepNumber = styled('div')`
  background: ${theme.vars.palette.primary.solidBg};
  color: ${theme.vars.palette.primary.solidColor};
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
  flex-shrink: 0;
`;

const Image = styled('img')`
  border-radius: ${theme.vars.radius.md};
  margin-top: 12px;
`;
