import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ApiError, DeputyResourceService } from './generated';

export function useUnexpiredNonRevokedDeputiesQuery(practiceId: string) {
  return useQuery({
    queryKey: getQueryKeyForUnexpiredNonRevokedDeputiesQuery(practiceId),
    queryFn: () => DeputyResourceService.getUnexpiredNonRevokedDeputiesOfOfferingUserAndPractice(practiceId),
  });
}

export function useInvalidateUnexpiredNonRevokedDeputiesQuery(practiceId: string) {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries({ queryKey: getQueryKeyForUnexpiredNonRevokedDeputiesQuery(practiceId) }),
    [queryClient, practiceId],
  );
}

const getQueryKeyForUnexpiredNonRevokedDeputiesQuery = (practiceId: string) => ['deputies', practiceId];

export const useDeputyInformationQuery = (deputyId: string) => {
  return useQuery({
    queryKey: ['deputy', 'information', deputyId],
    queryFn: () => DeputyResourceService.getOfferedDeputyForDeputyUser(deputyId),
    retry: (failureCount, error) => {
      if (error instanceof ApiError && (error.status === 403 || error.status === 410)) {
        // wrong user (403) and expired (410) are handled and can be displayed immediately
        return false;
      }
      return failureCount < 3;
    },
  });
};
