import { Radio, radioClasses, RadioGroup, styled } from '@healthinal/ui';

const DURATION_IN_DAYS_OPTIONS = [7, 14, 30, 90] as const;

export type DurationInDays = (typeof DURATION_IN_DAYS_OPTIONS)[number];

interface Props {
  value: DurationInDays;
  onChange: (value: DurationInDays) => void;
}

export function DurationSelector({ value, onChange }: Props) {
  return (
    <RadioGroup
      value={value}
      onChange={(event) => onChange(Number(event.target.value) as DurationInDays)}
      orientation="horizontal"
      variant="outlined"
      sx={{
        '--RadioGroup-gap': 0,
        margin: 0,
      }}>
      {DURATION_IN_DAYS_OPTIONS.map((days) => (
        <StyledRadio
          key={days}
          value={days}
          label={`${days} Tage`}
          disableIcon
          variant={value === days ? 'soft' : 'outlined'}
          color="neutral"
          slotProps={{
            action: {
              sx: { borderRadius: 0 },
            },
          }}
        />
      ))}
    </RadioGroup>
  );
}

const StyledRadio = styled(Radio)(({ theme }) => ({
  '--variant-softBg': theme.palette.neutral.softHoverBg,
  '--variant-softActiveBg': theme.palette.neutral.softHoverBg,
  '&:not([data-last-child])': {
    borderRight: `solid 1px ${theme.palette.neutral.outlinedBorder}`,
  },
  '&[data-first-child]': {
    [`.${radioClasses.action}`]: {
      borderTopLeftRadius: `calc(${theme.radius.sm} - 1px)`,
      borderBottomLeftRadius: `calc(${theme.radius.sm} - 1px)`,
    },
  },
  '&[data-last-child]': {
    [`.${radioClasses.action}`]: {
      borderTopRightRadius: `calc(${theme.radius.sm} - 1px)`,
      borderBottomRightRadius: `calc(${theme.radius.sm} - 1px)`,
    },
  },
  padding: '0 12px',
  fontSize: theme.fontSize.sm,
  lineHeight: '34px',
  fontWeight: theme.fontWeight.lg,
  whiteSpace: 'nowrap',
}));
