import { useSuspenseQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import {
  CallcenterResourceService,
  HeurekaMedicationStatementDto,
  HeurekaPatientDetailsDto,
  HeurekaPatientDto,
  HeurekaResourceService,
} from './generated';

export interface HeurekaPatient extends Omit<HeurekaPatientDto, 'dateOfBirth'> {
  dateOfBirth?: Date;
}

export interface HeurekaMedicationStatement
  extends Omit<HeurekaMedicationStatementDto, 'from' | 'until' | 'dosingSchedule'> {
  from?: Date;
  until?: Date;
  dosingSchedule?: string | null | string[];
}

export interface HeurekaPatientDetails {
  patient: HeurekaPatient;
  medicationStatements: HeurekaMedicationStatement[];
}

export const useHeurekaPatientQuery = (practiceId: string, patientId: string) => {
  const { data } = useSuspenseQuery<HeurekaPatientDetailsDto, unknown, HeurekaPatientDetails>({
    queryKey: ['heureka', practiceId, patientId],
    queryFn: () => HeurekaResourceService.getHeurekaPatient(patientId),
    select: transformHeurekaPatientDetails,
  });
  return data;
};

export const useHeurekaPatientQueryCallcenter = (patientId: string) => {
  const { data } = useSuspenseQuery<HeurekaPatientDetailsDto, unknown, HeurekaPatientDetails>({
    queryKey: ['callcenter', patientId],
    queryFn: () => CallcenterResourceService.getPatientDetails1(patientId),
    select: transformHeurekaPatientDetails,
  });
  return data;
};

const transformHeurekaPatientDetails = (details: HeurekaPatientDetailsDto): HeurekaPatientDetails => ({
  patient: transformHeurekaPatient(details.patient),
  medicationStatements: details.medicationStatements.map((medicationStatement) => ({
    ...medicationStatement,
    dosingSchedule: medicationStatement.dosages ? dosagesToSchedule(medicationStatement.dosages) : undefined,
    from: medicationStatement.from ? parseISO(medicationStatement.from) : undefined,
    until: medicationStatement.until ? parseISO(medicationStatement.until) : undefined,
  })),
});

export const transformHeurekaPatient = (patient: HeurekaPatientDto): HeurekaPatient => ({
  ...patient,
  dateOfBirth: patient.dateOfBirth ? parseISO(patient.dateOfBirth) : undefined,
});

const dosagesToSchedule = (dosages: Record<string, string>): string[] | string | null => {
  if (dosages?.NONE !== undefined) {
    return dosages?.NONE;
  } else {
    const dosagesKeys = {
      ACM: 'vor Frühstück',
      CM: 'mit Frühstück',
      PCM: 'nach Frühstück',
      '10:00': 'um 10:00',
      ACD: 'vor Mittagessen',
      CD: 'mit Mittagessen',
      PCD: 'nach Mittagessen',
      '16:00': 'um 16:00',
      ACV: 'vor Abendessen',
      CV: 'mit Abendessen',
      PCV: 'nach Abendessen',
      HS: 'vor Schlafen',
    };

    return Object.entries(dosages)
      .filter(([_, amount]) => amount !== null && amount !== undefined)
      .map(([key, amount]) => `${amount} ${dosagesKeys[key as keyof typeof dosagesKeys]}`);
  }
};
