import { atom } from 'jotai';

export const patientsSearchTextAtom = atom('');

export enum PatientSearchStatus {
  ALL = 'ALL',
  NOT_FOUND = 'NOT_FOUND',
  SHARED = 'SHARED',
  ACCESS_REVOKED = 'ACCESS_REVOKED',
  SHARING_SOON = 'SHARING_SOON',
  NEVER_SEEN = 'NEVER_SEEN',
}

export const patientsSearchStatusAtom = atom<PatientSearchStatus>(PatientSearchStatus.ALL);
