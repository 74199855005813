/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { HeurekaPatientDetailsDto } from '../models/HeurekaPatientDetailsDto';
import type { PracticeDto } from '../models/PracticeDto';
export class CallcenterResourceService {
  /**
   * @param patientId
   * @param practiceId
   * @param expiration
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static sharePatientWithPartner(
    patientId: string,
    practiceId: string,
    expiration: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/callcenter/patients/{patientId}/share',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
        expiration: expiration,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns HeurekaPatientDetailsDto OK
   * @throws ApiError
   */
  public static getPatientDetails1(
    patientId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<HeurekaPatientDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/callcenter/patients/{patientId}',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param insurance
   * @param insuranceCustomerNr
   * @param requestId
   * @param user
   * @param created
   * @param hmac
   * @param ahv
   * @param smcCsrfProtection
   * @returns boolean OK
   * @throws ApiError
   */
  public static isPatientDataAvailable(
    insurance: string,
    insuranceCustomerNr: string,
    requestId: string,
    user: string,
    created: string,
    hmac: string,
    ahv?: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/callcenter/patient/exists',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        insurance: insurance,
        insuranceCustomerNr: insuranceCustomerNr,
        ahv: ahv,
        requestId: requestId,
        user: user,
        created: created,
        hmac: hmac,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns PracticeDto OK
   * @throws ApiError
   */
  public static getPartnerPractices(
    patientId?: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<Array<PracticeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/callcenter/partnerpractices',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        patientId: patientId,
      },
    });
  }
  /**
   * @param zsr
   * @param requestId
   * @param user
   * @param created
   * @param hmac
   * @param smcCsrfProtection
   * @returns string OK
   * @throws ApiError
   */
  public static getEmailAddress(
    zsr: string,
    requestId: string,
    user: string,
    created: string,
    hmac: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/callcenter/email',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        zsr: zsr,
        requestId: requestId,
        user: user,
        created: created,
        hmac: hmac,
      },
    });
  }
}
