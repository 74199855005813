import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { PatientDto, PatientResourceService, SharedPatientDto } from './generated';

const transformPatient = <T extends PatientDto | SharedPatientDto>(patient: T) => ({
  ...patient,
  birthDate: patient.birthDate && format(parseISO(patient.birthDate), 'dd.MM.yyyy'),
});

export const usePatientsQuery = (practiceId: string) => {
  const { data } = useSuspenseQuery({
    queryKey: ['patients', practiceId],
    queryFn: () => PatientResourceService.getPatientsByPractice(practiceId),
    select: (patients) => patients.map(transformPatient),
    staleTime: 1000 * 60 * 5,
    refetchInterval: (query) => {
      const patients = query.state.data;
      if (patients?.some((patient) => patient.status === 'SYNCING')) {
        return 1000;
      }
    },
  });
  return data;
};

export const useSharedPatientsQuery = (practiceId: string) => {
  const { data } = useQuery({
    queryKey: ['patients', 'shared', practiceId],
    queryFn: async () => PatientResourceService.getPatientsSharedWithPractice(practiceId),
    select: (patients) => patients.map(transformPatient),
  });
  return data;
};

export const usePatientQuery = (practiceId: string, patientId: string) => {
  const { data } = useSuspenseQuery({
    queryKey: ['patient', practiceId, patientId],
    queryFn: () => PatientResourceService.getPatientDetails(patientId),
    select: transformPatient,
  });
  return data;
};
