import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { PracticeDto } from './api/generated';
import { whoamiAtom } from './api/whoami';
import { PatientSearchStatus, patientsSearchStatusAtom } from './patientSearch';

const SELECTED_ORGANIZATION_STORAGE_KEY = 'selectedOrganizationKey';
const selectedOrganizationKeyAtom = atomWithStorage<string | null>(
  SELECTED_ORGANIZATION_STORAGE_KEY,
  window.localStorage.getItem(SELECTED_ORGANIZATION_STORAGE_KEY),
);

const selectedOrganizationAtom = atom(
  async (get) => {
    const { data: whoami } = await get(whoamiAtom);
    const { practices, deputies } = whoami;
    const allPractices = [...practices, ...deputies.map(({ practice }) => practice)];
    const storedSelectedOrganizationKey = get(selectedOrganizationKeyAtom);
    if (storedSelectedOrganizationKey) {
      const storedSelectedOrganization = allPractices.find((practice) => practice.id === storedSelectedOrganizationKey);
      if (storedSelectedOrganization) {
        return storedSelectedOrganization;
      }
    }
    return allPractices[0];
  },
  (get, set, arg: PracticeDto) => {
    set(selectedOrganizationKeyAtom, arg.id);
    set(patientsSearchStatusAtom, PatientSearchStatus.ALL);
  },
);

export { selectedOrganizationAtom };
