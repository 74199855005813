import { Card, Divider, Stack, Switch, Table, Typography, styled } from '@healthinal/ui';
import { endOfYesterday, isAfter } from 'date-fns';
import { HTMLProps, useState } from 'react';
import { HeurekaMedicationStatement } from '../state/api/heurekaPatientDetails';
import { theme } from '../theme';
import { formatDate } from '../utils/date';
import { ExpandableCell } from './ExpandableCell';
import { TabularNumbers } from './TabularNumbers';

interface Props {
  title: string;
  medicationStatements: HeurekaMedicationStatement[];
}

export default function MedicationTable({ medicationStatements, title }: Props) {
  const [showInactiveMedication, setShowInactiveMedication] = useState(false);

  const filteredMedicationStatements = medicationStatements
    .filter((medication) => showInactiveMedication || isMedicationActive(medication))
    .sort((a, b) => {
      const aActive = isMedicationActive(a);
      const bActive = isMedicationActive(b);
      if (aActive !== bActive) {
        return aActive ? -1 : 1;
      }
      return a.medicationName.localeCompare(b.medicationName);
    });

  return (
    <StyledCard>
      <CardHeader>
        <Typography level="title-md" fontWeight={600}>
          {title}
        </Typography>
        <Divider orientation="vertical" />
        <Typography
          component="label"
          level="body-sm"
          textColor="text.secondary"
          startDecorator={
            <Switch
              checked={showInactiveMedication}
              onChange={(e) => setShowInactiveMedication(e.target.checked)}
              sx={{ mr: 1 }}
            />
          }>
          Frühere Medikamente anzeigen
        </Typography>
      </CardHeader>
      <CardContent>
        <StyledTable noWrap>
          <thead>
            <tr>
              <th>Medikament</th>
              <th style={{ width: 200 }}>Dosierung</th>
              <th>Bemerkung</th>
              <th style={{ width: 232 }}>Dauer</th>
            </tr>
          </thead>
          <tbody>
            {filteredMedicationStatements.map((medication, index) => {
              return <MedicationRow medication={medication} key={index} />;
            })}
          </tbody>
        </StyledTable>
      </CardContent>
    </StyledCard>
  );
}

const isMedicationActive = (medication: HeurekaMedicationStatement) =>
  // endOfYesterday() so medication ending today is still considered active
  !medication.until || isAfter(medication.until, endOfYesterday());

const StyledCard = styled(Card)`
  --Card-padding: 0;
  overflow: hidden; // for rounded corners
  margin-bottom: 40px;
`;

const CardHeader = styled('div')`
  padding: 24px 32px;
  border-bottom: solid 1px ${theme.vars.palette.divider};
  display: flex;
  gap: 24px;
`;

const CardContent = styled('div')`
  padding: 0 16px 16px;
`;

const StyledTable = styled(Table)`
  --Table-headerUnderlineThickness: 1px;
  --TableCell-paddingX: 16px;
  --TableCell-paddingY: 9px;

  thead th {
    ${theme.typography['title-sm']};
    text-transform: uppercase;
    vertical-align: middle;
    font-size: 12px;
  }

  td {
    vertical-align: top;
  }

  td,
  th {
    &:not(:first-of-type) {
      border-left: solid 1px var(--TableCell-borderColor);
    }
  }
`;

interface MedicationRowProps extends HTMLProps<HTMLTableRowElement> {
  medication: HeurekaMedicationStatement;
}

const MedicationRow = ({ medication, ...trProps }: MedicationRowProps) => {
  const isActive = isMedicationActive(medication);
  const [open, setOpen] = useState(false);

  const getDosingScheduleText = () => {
    if (!medication.dosingSchedule) {
      return;
    }
    if (typeof medication.dosingSchedule === 'string') {
      return medication.dosingSchedule;
    }
    return open ? medication.dosingSchedule.join('\n') : medication.dosingSchedule.join(', ');
  };

  return (
    <tr {...trProps}>
      <ExpandableCell
        isOpen={open}
        onCellClick={() => setOpen(!open)}
        style={{ fontWeight: isActive ? 550 : undefined }}>
        {medication.medicationName}
      </ExpandableCell>
      <ExpandableCell isOpen={open} onCellClick={() => setOpen(!open)}>
        {getDosingScheduleText()}
      </ExpandableCell>
      <ExpandableCell isOpen={open} onCellClick={() => setOpen(!open)}>
        {medication.comment}
      </ExpandableCell>
      <td>
        {(medication.from || medication.until) && (
          <Stack direction="row" alignItems="flex-end" gap={1}>
            <TabularNumbers>{formatDate(medication.from)}</TabularNumbers>
            <Typography fontSize={12} textColor={'neutral.300'}>
              bis
            </Typography>
            <TabularNumbers>{formatDate(medication.until)}</TabularNumbers>
          </Stack>
        )}
      </td>
    </tr>
  );
};
