/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { LinkHeurekaDto } from '../models/LinkHeurekaDto';
import type { PatientDto } from '../models/PatientDto';
import type { SharedPatientDto } from '../models/SharedPatientDto';
export class PatientResourceService {
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static unlinkHeurekaPatientById(patientId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/unlinkheureka',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static setStatusToNeverSeen(patientId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/status/neverseen',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param patientId
   * @param requestBody
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static linkHeurekaPatientById(
    patientId: string,
    requestBody: LinkHeurekaDto,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/linkheureka',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static revokeCallcenterAccess(patientId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/externalaccess/revoke',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static makeExternallyAccessibleNow(patientId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/externalaccess/grantnow',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static grantCallcenterAccess(patientId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/{patientId}/externalaccess/grant',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static setExternalAccessRevokedByPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/externalaccess/revoke',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static makeExternallyAccessibleNowByPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/externalaccess/grantnow',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static setExternalAccessGrantedByPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/patients/externalaccess/grant',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns PatientDto OK
   * @throws ApiError
   */
  public static getPatientsByPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<Array<PatientDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/patients',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns PatientDto OK
   * @throws ApiError
   */
  public static getPatientDetails(patientId: string, smcCsrfProtection?: any): CancelablePromise<PatientDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/patients/{patientId}',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns SharedPatientDto OK
   * @throws ApiError
   */
  public static getPatientsSharedWithPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<Array<SharedPatientDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/patients/sharedwith',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
}
