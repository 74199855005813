import {
  browserTracingIntegration,
  captureException,
  getCurrentScope,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
} from '@sentry/react';
import axios, { AxiosError, isAxiosError } from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { environment } from './utils/environment';

const key = 'sentry-user-id';

const sentrySessionId = uuidV4();

const sentryUserId =
  localStorage?.getItem(key) ??
  (() => {
    const id = uuidV4();
    localStorage?.setItem(key, id);
    return id;
  })();

const sentryRequestHeaders = {
  'sentry-custom-session-id': sentrySessionId,
  'sentry-custom-user-id': sentryUserId,
};

const expectedErrorStatusCodes: [RegExp, number][] = [
  [new RegExp('/api/heureka/practices/[^/]+/patients\\?pid=.*'), 404],
  [new RegExp('/api/patients/[^/]+/linkheureka'), 409],
  [new RegExp('/api/deputies/[^/]+'), 403], // wrong user opened deputy link
  [new RegExp('/api/deputies/[^/]+'), 410], // expired deputy
];

const ignoredAxiosErrorCodes = [AxiosError.ERR_CANCELED, AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED];

const shouldCaptureException = (error: unknown) => {
  if (isAxiosError(error)) {
    if (error.code && ignoredAxiosErrorCodes.includes(error.code)) {
      return false;
    }
    if (error.response?.config.url) {
      for (const [pattern, statusCode] of expectedErrorStatusCodes) {
        if (error.response.status === statusCode && pattern.test(error.response.config.url)) {
          return false;
        }
      }
    }
  }
  return true;
};

const instrumentAxios = () => {
  Object.entries(sentryRequestHeaders).forEach(([header, value]) => (axios.defaults.headers.common[header] = value));
  axios.interceptors.response.use(
    (i) => i,
    (error) => {
      if (shouldCaptureException(error)) {
        captureException(error);
      }
      return Promise.reject(error);
    },
  );
};

export const setupSentry = () => {
  init({
    dsn: environment === 'localhost' ? '' : `${import.meta.env.VITE_SENTRY_DSN}`,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.1,
    autoSessionTracking: false,
    environment,
    release: `${import.meta.env.VITE_APP_COMMIT_ID}`,
    normalizeDepth: 10,
  });
  instrumentAxios();
  getCurrentScope().setTag('session_id', sentrySessionId).setTag('user_id', sentryUserId);
};
