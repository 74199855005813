/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { CreateDeputyDto } from '../models/CreateDeputyDto';
import type { DeputyDto } from '../models/DeputyDto';
import type { DeputyUserSearchResultDto } from '../models/DeputyUserSearchResultDto';
import type { RejectDeputyDto } from '../models/RejectDeputyDto';
export class DeputyResourceService {
  /**
   * @param requestBody
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static createDeputy(requestBody: CreateDeputyDto, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/deputies',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param id
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static revokeDeputy(id: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/deputies/{id}/revoke',
      path: {
        id: id,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param deputyId
   * @param requestBody
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static rejectDeputy(
    deputyId: string,
    requestBody: RejectDeputyDto,
    smcCsrfProtection?: any,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/deputies/{deputyId}/reject',
      path: {
        deputyId: deputyId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param deputyId
   * @param smcCsrfProtection
   * @returns any OK
   * @throws ApiError
   */
  public static acceptDeputy(deputyId: string, smcCsrfProtection?: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/deputies/{deputyId}/accept',
      path: {
        deputyId: deputyId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param id
   * @param smcCsrfProtection
   * @returns DeputyDto OK
   * @throws ApiError
   */
  public static getOfferedDeputyForDeputyUser(id: string, smcCsrfProtection?: any): CancelablePromise<DeputyDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/deputies/{id}',
      path: {
        id: id,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns DeputyDto OK
   * @throws ApiError
   */
  public static getUnexpiredNonRevokedDeputiesOfOfferingUserAndPractice(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<Array<DeputyDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/deputies/unexpirednonrevoked',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        practiceId: practiceId,
      },
    });
  }
  /**
   * @param nameQuery
   * @param deputyPracticeId
   * @param smcCsrfProtection
   * @returns DeputyUserSearchResultDto OK
   * @throws ApiError
   */
  public static getDeputyUserByName(
    nameQuery: string,
    deputyPracticeId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<Array<DeputyUserSearchResultDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/deputies/finddeputyuser',
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        nameQuery: nameQuery,
        deputyPracticeId: deputyPracticeId,
      },
    });
  }
}
