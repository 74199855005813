export function hostnameToEnvironment(hostname: string) {
  if (hostname.includes('localhost')) {
    return 'localhost';
  } else if (hostname.includes('test')) {
    return 'testing';
  } else {
    return 'production';
  }
}

export const environment = hostnameToEnvironment(window.location.hostname);
