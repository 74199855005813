import { styled } from '@healthinal/ui';
import { ReactNode } from 'react';

const ContentWrapper = styled('section')`
  padding: 0 16px;
  > div {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export default function ContentSection({ className, children }: { className?: string; children: ReactNode }) {
  return (
    <ContentWrapper className={className}>
      <div>{children}</div>
    </ContentWrapper>
  );
}
