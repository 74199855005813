import { Icons, styled } from '@healthinal/ui';
import { HTMLProps, useEffect, useRef, useState } from 'react';
import { theme } from '../theme';

interface ExpandableCellProps extends HTMLProps<HTMLTableCellElement> {
  onCellClick: () => void;
  isOpen: boolean;
}

export const ExpandableCell = ({ children, onCellClick, isOpen, ...tdProps }: ExpandableCellProps) => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const tableCell = textContainerRef.current;

    if (!tableCell) {
      return;
    }
    const checkTruncation = () => {
      const isOverflowing = tableCell.scrollWidth > tableCell.clientWidth;
      const isExpanded = tableCell.offsetHeight > 21;
      setIsTruncated(isOverflowing || isExpanded);
    };

    checkTruncation();

    const resizeObserver = new ResizeObserver(checkTruncation);
    resizeObserver.observe(tableCell);

    return () => {
      resizeObserver.unobserve(tableCell);
    };
  }, []);

  useEffect(() => {
    if (textContainerRef.current) {
      if (isOpen) {
        animateExpand(textContainerRef.current, 'pre-line');
      } else {
        animateExpand(textContainerRef.current, 'nowrap');
      }
    }
  }, [isOpen]);

  const handleClick = () => {
    const selection = window.getSelection();
    if (selection?.type != 'Range') {
      onCellClick();
    }
  };

  return (
    <StyledCell
      {...tdProps}
      onClick={isTruncated ? handleClick : undefined}
      className={isTruncated ? 'truncated' : ''}
      as={'td'}>
      <CellContainer>
        <TextContainer ref={textContainerRef}>{children}</TextContainer>
        {isTruncated && (isOpen ? <Icons.Remove sx={iconStyle} /> : <Icons.Add sx={iconStyle} />)}
      </CellContainer>
    </StyledCell>
  );
};

function animateExpand(cellContainer: HTMLDivElement, whiteSpace: string) {
  const initialHeight = cellContainer.offsetHeight;

  cellContainer.style.whiteSpace = whiteSpace;

  const targetHeight = cellContainer.offsetHeight;

  if (targetHeight === initialHeight) {
    return;
  }

  cellContainer.style.height = `${initialHeight}px`;

  requestAnimationFrame(() => {
    cellContainer.style.height = `${targetHeight}px`;

    cellContainer.addEventListener(
      'transitionend',
      () => {
        cellContainer.style.height = '';
      },
      { once: true },
    );
  });
}

const StyledCell = styled('td')`
  transition: background-color 0.3s ease-in-out;

  &.truncated:hover {
    background-color: ${theme.vars.palette.primary[50]};
    cursor: pointer;
  }
`;

const CellContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TextContainer = styled('div')`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: height 0.25s ease-in-out;
`;

const iconStyle = {
  justifySelf: 'center',
  background: theme.vars.palette.primary[50],
  borderRadius: '50%',
  fontSize: '18px',
};
