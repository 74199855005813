import { Stack, Typography } from '@healthinal/ui';
import { ReactNode } from 'react';

interface DataItemProps {
  label: string;
  children: ReactNode;
}

export function DataItem({ label, children }: DataItemProps) {
  return (
    <Stack gap={0.5}>
      <Typography level="title-sm" textColor={'neutral.400'}>
        {label}
      </Typography>
      <Typography textColor={'neutral.900'}>{children}</Typography>
    </Stack>
  );
}
