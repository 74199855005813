import { CircularProgress, Stack } from '@healthinal/ui';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ComponentRef, ComponentType, PropsWithChildren, ReactNode, Suspense, forwardRef } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { InlineError } from './ErrorComponents';

export default function withQueryWrapper<P extends object>(
  Component: ComponentType<P>,
  {
    renderErrorFallback = InlineError,
    loadingFallback = <LoadingFallback />,
    Wrapper = ({ children }: { children: ReactNode }) => <>{children}</>,
  }: {
    renderErrorFallback?: (props: FallbackProps) => ReactNode;
    loadingFallback?: ReactNode;
    Wrapper?: ComponentType<{ children: ReactNode }>;
  } = {},
) {
  return forwardRef<ComponentRef<typeof Component>, P>(function QueryWrapper(props: PropsWithChildren<P>, ref) {
    return (
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary onReset={reset} fallbackRender={(props) => <Wrapper>{renderErrorFallback(props)}</Wrapper>}>
            <Suspense fallback={<Wrapper>{loadingFallback}</Wrapper>}>
              <Component {...props} ref={ref} />
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    );
  });
}

function LoadingFallback() {
  return (
    <Stack alignItems="center" my={10}>
      <CircularProgress variant="soft" size="sm" />
    </Stack>
  );
}
