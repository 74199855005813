import { atom } from 'jotai';
import { whoamiAtom } from './api/whoami';
import { selectedOrganizationAtom } from './selectedOrganization';

export const selectedDeputyAtom = atom(async (get) => {
  const { data: whoami } = await get(whoamiAtom);
  const { deputies } = whoami;
  const selectedOrganization = await get(selectedOrganizationAtom);
  return deputies.find((deputy) => deputy.practice === selectedOrganization);
});
