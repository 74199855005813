import {
  Avatar,
  Card,
  CardContent,
  Dropdown,
  Icons,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
  styled,
} from '@healthinal/ui';
import { format, parseISO } from 'date-fns';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PracticeDto } from '../state/api/generated';
import { whoamiAtom } from '../state/api/whoami';
import { selectedOrganizationAtom } from '../state/selectedOrganization';
import { theme } from '../theme';
import { Help } from './Help';

const UserCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  border: none;
`;

const UserCardContent = styled(CardContent)`
  line-height: 18px;
`;

const UserMenuButton = styled(MenuButton)`
  text-align: left;
  font-weight: inherit;
`;

const UserMenuItem = styled(MenuItem)`
  justify-content: space-between;
`;

export default function UserMenu() {
  const [{ data: whoami }] = useAtom(whoamiAtom);
  const { user, practices, deputies } = whoami;
  const [selectedOrganization, setSelectedOrganization] = useAtom(selectedOrganizationAtom);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const fullName = user.firstName && user.lastName && `${user.firstName} ${user.lastName}`;
  const username = fullName ?? user.hinId ?? user.email;
  const switchToPractice = (practice: PracticeDto) => {
    setSelectedOrganization(practice);
    if (pathname !== '/') {
      navigate('/');
    }
  };

  return (
    <>
      <Dropdown onOpenChange={() => setIsExpanded(!isExpanded)}>
        <UserMenuButton
          data-testid={'userMenu'}
          variant={'plain'}
          endDecorator={isExpanded ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
          sx={isExpanded ? { backgroundColor: theme.vars.palette.neutral[50] } : {}}>
          <UserCardContent>
            <Typography level={'title-md'} fontWeight={600}>
              {selectedOrganization.name}
            </Typography>
            <Typography level={'body-sm'}>{username}</Typography>
          </UserCardContent>
        </UserMenuButton>
        <Menu>
          <UserCard>
            <Avatar variant={'soft'} size={'md'} />
            <UserCardContent>
              <Typography level={'title-md'}>{username}</Typography>
              <Typography level={'body-sm'}>{user.email}</Typography>
            </UserCardContent>
          </UserCard>
          <ListDivider inset={'gutter'} />
          {practices.map((practice) => (
            <UserMenuItem key={practice.id} onClick={() => switchToPractice(practice)}>
              {practice.name}
              {practice == selectedOrganization && (
                <ListItemDecorator>
                  <Icons.Check />
                </ListItemDecorator>
              )}
            </UserMenuItem>
          ))}
          {deputies.map(({ practice, endDate }) => (
            <UserMenuItem key={practice.id} onClick={() => switchToPractice(practice)}>
              <div>
                {practice.name}
                <Typography level={'body-sm'}>Vertretung bis {format(parseISO(endDate), 'dd.MM.yy')}</Typography>
              </div>
              {practice == selectedOrganization && (
                <ListItemDecorator>
                  <Icons.Check />
                </ListItemDecorator>
              )}
            </UserMenuItem>
          ))}

          <ListDivider inset={'gutter'} />

          <UserMenuItem onClick={() => setIsHelpOpen(true)}>
            Hilfe
            <ListItemDecorator>
              <Icons.HelpOutline />
            </ListItemDecorator>
          </UserMenuItem>
          <UserMenuItem onClick={() => (window.location.href = '/?logout')}>
            Abmelden
            <ListItemDecorator>
              <Icons.Logout />
            </ListItemDecorator>
          </UserMenuItem>
        </Menu>
      </Dropdown>
      <Help open={isHelpOpen} close={() => setIsHelpOpen(false)} />
    </>
  );
}
