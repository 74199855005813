import { Button, Icons, Sheet, Typography, styled } from '@healthinal/ui';
import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import Logo from '../assets/logo.svg?react';
import { useSharedPatientsQuery } from '../state/api/patients';
import { selectedDeputyAtom } from '../state/selectedDeputy';
import { selectedOrganizationAtom } from '../state/selectedOrganization';
import { theme } from '../theme';
import { NavigationDeputyBanner } from './DeputyBanner';
import UserMenu from './UserMenu';

const StyledLogo = styled(Logo)`
  fill: white;
  height: 50px;
  width: auto;
`;

const Root = styled(Sheet)`
  border-bottom: 1px solid ${theme.vars.palette.primary[50]};
  padding: 16px 16px;
  > div {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
`;

const TitleLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled(Typography)`
  font-size: 14px;
  line-height: calc(40px / 3);
  font-weight: 700;
  color: ${theme.vars.palette.primary[600]};
`;

const Controls = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default function Header() {
  const [selectedDeputy] = useAtom(selectedDeputyAtom);
  return (
    <HeaderWrapper>
      <Navigation>{!selectedDeputy ? <MyPracticeNavigationItems /> : <NavigationDeputyBanner />}</Navigation>
      <Controls>
        <UserMenu />
      </Controls>
    </HeaderWrapper>
  );
}

function MyPracticeNavigationItems() {
  const [selectedOrganization] = useAtom(selectedOrganizationAtom);
  const sharedPatients = useSharedPatientsQuery(selectedOrganization.id) ?? [];

  return (
    <>
      <NavigationLink to="/practice">Meine SMC Patient:innen</NavigationLink>
      {sharedPatients.length > 0 && <NavigationLink to="/shared">Für mich freigegeben</NavigationLink>}
      <NavigationLink to="/settings">Einstellungen</NavigationLink>
    </>
  );
}

const Navigation = styled('div')`
  display: flex;
  gap: 16px;
`;

function NavigationLink(props: NavLinkProps & { children: ReactNode }) {
  return (
    <StyledLink {...props}>
      {({ isActive }) => (
        <Button
          size="lg"
          component="span"
          variant={isActive ? 'soft' : 'plain'}
          color={isActive ? 'primary' : 'neutral'}
          tabIndex={-1}
          sx={isActive ? { cursor: 'default' } : { cursor: 'pointer' }}>
          {props.children}
        </Button>
      )}
    </StyledLink>
  );
}

export function ErrorHeader() {
  return (
    <HeaderWrapper>
      <Controls>
        <Button fullWidth component={'a'} href={'/?logout'} endDecorator={<Icons.Logout />}>
          Abmelden
        </Button>
      </Controls>
    </HeaderWrapper>
  );
}

interface HeaderWrapperProps {
  children: ReactNode;
}

function HeaderWrapper({ children }: HeaderWrapperProps) {
  return (
    <header>
      <Root>
        <div>
          <TitleLink to="/">
            <StyledLogo />
            <Title level="h1">
              Smart
              <br />
              Managed
              <br />
              Care
            </Title>
          </TitleLink>
          {children}
        </div>
      </Root>
    </header>
  );
}

const StyledLink = styled(NavLink)(({ theme }) => ({
  borderRadius: theme.radius.sm,
  '--variant-softHoverBg': theme.palette.primary.softBg,
  '--variant-softActiveBg': theme.palette.primary.softBg,
  '--variant-softActiveColor': theme.palette.primary.softColor,
}));
