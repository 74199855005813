import { createTheme } from '@healthinal/ui';

export const theme = createTheme(
  {
    primary: {
      hue: 184,
    },
    neutral: {
      hue: 184,
    },
  },
  {
    components: {
      JoyTooltip: {
        styleOverrides: {
          root: {
            maxWidth: 440,
          },
        },
        defaultProps: {
          arrow: true,
          variant: 'outlined',
        },
      },
    },
  },
);
