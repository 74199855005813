import { Alert, Icons, Stack } from '@healthinal/ui';
import { ReactNode } from 'react';
import { HeurekaMedicationStatement, HeurekaPatientDetails } from '../state/api/heurekaPatientDetails';
import { formatAhv } from '../utils/ahv';
import { formatDate } from '../utils/date';
import { DataItem } from './DataItem';
import MedicationTable from './MedicationTable';

interface Props {
  patientData: HeurekaPatientDetails;
  headerAction?: ReactNode;
}

export default function HeurekaPatient({ patientData, headerAction }: Props) {
  const { patient, medicationStatements } = patientData;

  const regularMedication: HeurekaMedicationStatement[] = [];
  const asNeededMedication: HeurekaMedicationStatement[] = [];
  medicationStatements.forEach((medication) => {
    if (medication.asNeeded) {
      asNeededMedication.push(medication);
    } else {
      regularMedication.push(medication);
    }
  });

  return (
    <>
      <Alert variant="soft" startDecorator={<Icons.ErrorOutline />} sx={{ my: 3 }}>
        Achtung diese Daten können unvollständig, fehlerhaft oder nicht mehr aktuell sein und müssen deshalb vor
        Verwendung mit dem:der Patient:in validiert werden. Der Urheber der Informationen sowie der Betreiber des
        Dashboards übernehmen keine Haftung für die Vollständigkeit oder Richtigkeit der Daten.
      </Alert>

      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
        <Stack direction="row" gap={7}>
          <DataItem label="Patient:in">
            {patient.firstName} {patient.lastName}
          </DataItem>
          <DataItem label="Geburtsdatum">{formatDate(patient.dateOfBirth)}</DataItem>
          <DataItem label="Patienten-ID">{patient.pid}</DataItem>
          <DataItem label="AHV-Nr">{formatAhv(patient.ahvNumber)}</DataItem>
        </Stack>
        {headerAction}
      </Stack>

      {medicationStatements.length === 0 && (
        <Alert variant="soft" startDecorator={<Icons.InfoOutlined />}>
          Keine Medikamente gefunden
        </Alert>
      )}

      {regularMedication.length > 0 && <MedicationTable medicationStatements={regularMedication} title="Medikation" />}
      {asNeededMedication.length > 0 && (
        <MedicationTable medicationStatements={asNeededMedication} title="Reservemedikation" />
      )}
    </>
  );
}
